// hooks
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRequestPost } from 'hooks/useRequest';
import html2canvas from 'html2canvas';
// image
import color_card from 'assets/home/color_card.png';
import loadingImg from 'assets/images/loadingImg.gif';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
// icons
import { FaRegCopy } from 'react-icons/fa';
// Components
import BasicTooltip from 'components/ToolTips/BasicToolTips';
// common
import { toast } from 'react-toastify'; // Toastify import
import 'react-toastify/dist/ReactToastify.css'; // Toastify 스타일 import

const ColorCard = ({ title, description, placeholder }) => {
    const captureRef = useRef();
    const textContentRef = useRef();
    const [inputValue, setInputValue] = useState('');
    const [isResultShow, setIsResultShow] = useState(false);
    const [result, setResult] = useState('');
    const [colorResult, setColorResult] = useState(false);
    const [descriptionResult, setDescriptionResult] = useState(false);
    const postColorCreate = useRequestPost();
    const createPost = () => {
        const data = {
            templateCode: 'C2201',
            firstVariable: inputValue,
        };
        if (inputValue) {
            // 1. 블로그인 경우 타이틀 먼저 생성 한 후, 콘텐츠 생성해야 함
            postColorCreate.mutate({
                requestUrl: '/api/free-chat-completion/run',
                postData: data,
            });
        }
    };
    //post TemplateCreate Handler
    useEffect(() => {
        if (postColorCreate.status === 'success') {
            if (postColorCreate?.data?.data) {
                setIsResultShow(true);
                setResult(postColorCreate?.data?.data);
            } else {
                toast.error('요청은 하루에 한번 가능합니다.', {
                    position: 'top-center',
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }, [postColorCreate.status]);

    useEffect(() => {
        if (result) {
            const splitedResult = result.value.split('\n\n');
            if (splitedResult.length > 1) {
                // 예상된 형식일 때
                const splitedColorResult = splitedResult[0].replace(/\s+/g, '');
                setColorResult(splitedColorResult);
                setDescriptionResult(splitedResult[1]);
            } else {
                // 예상과 다른 형식일 때
                setColorResult(false);
                setDescriptionResult(result.value);
            }
        }
    }, [result]);

    // 영역 이미지 다운로드
    const handleDownload = async (e) => {
        e.stopPropagation();
        const captureElement = captureRef.current;

        // Temporarily scale the element for capturing
        captureElement.style.transform = 'scale(3)';
        captureElement.style.transformOrigin = 'top left';
        captureElement.style.width = `${captureElement.offsetWidth}px`;
        captureElement.style.height = `${captureElement.offsetHeight}px`;

        // Use html2canvas to capture the element with scaling
        const canvas = await html2canvas(captureElement, { scale: 1 });

        // Reset the scale to normal
        captureElement.style.transform = '';
        captureElement.style.transformOrigin = '';

        const dataUrl = canvas.toDataURL('image/png');

        // Create an anchor element and set the href attribute to the data URL
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'plumbyte_color_card.png';
        link.click();
    };
    // 영역 복사
    const handleCopy = async (e) => {
        e.stopPropagation();
        try {
            await navigator.clipboard.writeText(descriptionResult);
            toast.success('클립보드에 복사되었습니다.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            console.error('복사 실패:', error);
        }
    };
    return (
        <>
            <div
                className="w-[49%] min-h-full z-10 flex justify-center items-center bg-cover rounded-2xl absolute right-0"
                style={{ backgroundImage: `url(${color_card})` }}
                onClick={() => setIsResultShow(!isResultShow)}
            >
                {postColorCreate.isPending && (
                    <div className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-screen">
                        <div className="relative">
                            <img
                                src={loadingImg}
                                alt="loading"
                                className="w-48 h-48"
                            />
                        </div>
                    </div>
                )}
                <div className="w-full px-6 py-4 select-none">
                    <div className="mb-3 text-text-white">
                        <div className="mb-1 text-lg font-bold">{title}</div>
                        <div className="mb-4 text-sm whitespace-pre-wrap">{description}</div>
                    </div>

                    {/* input bar */}
                    <div>
                        <div className="relative">
                            <input
                                id="inputValue"
                                type="text"
                                maxLength={100}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder={placeholder}
                                className="bg-transparent border bg-white border-text-white text-xs pr-20 focus:font-semibold focus:outline-none px-2 py-[10px] w-full rounded-lg placeholder-text-gray "
                            />
                            <button
                                onClick={() => createPost()}
                                type="button"
                                className={
                                    'bg-text-black text-white font-bold py-2 px-3 top-[3px] rounded-lg absolute right-[3px] text-xs'
                                }
                            >
                                결과보기
                            </button>
                        </div>
                    </div>
                    {/* result */}
                    <div>
                        {descriptionResult && isResultShow && (
                            <div className="p-4 mt-3 text-xs rounded-md bg-text-white">
                                <div className="flex items-center justify-between mb-3 text-sm font-bold">
                                    <div>🌈 오늘의 컬러카드</div>
                                    <BasicTooltip
                                        title="Copy"
                                        placement="top"
                                        contents={
                                            <FaRegCopy
                                                className="w-4 h-4 text-gray-dGray"
                                                onClick={(e) => handleCopy(e)}
                                            />
                                        }
                                    />
                                </div>

                                <div className="flex">
                                    {colorResult && (
                                        <div
                                            ref={captureRef}
                                            className="p-3 mr-3 border rounded-lg shadow-md bg-text-white h-fit"
                                        >
                                            <div
                                                style={{ backgroundColor: colorResult }}
                                                className="w-24 h-24 rounded-lg"
                                            ></div>
                                            <div
                                                style={{ color: colorResult }}
                                                className="mt-4 mb-2 font-bold text-center"
                                            >
                                                {colorResult}
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="whitespace-pre-wrap"
                                        ref={textContentRef}
                                    >
                                        {descriptionResult}
                                    </div>
                                </div>
                                <div className="text-center text-[10px] mt-2">
                                    <div className="inline m-2 text-main-pink">저장하고 싶다면?</div>
                                    <button
                                        onClick={(e) => handleDownload(e)}
                                        className="px-2 py-1 font-bold border rounded-lg text-main-pink border-main-pink"
                                    >
                                        다운받기
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ColorCard;
