import kakaoTalk from 'assets/logo/social/kakaoTalk.png';
import kakao from 'assets/logo/social/kakao.png';

export default function KakaoBtn() {
    const link = `https://dev.plumbyte-ai.com/oauth2/authorization/kakao`;
    // const link = `http://43.200.27.171:8080/oauth2/authorization/kakao`;
    const handleLogin = () => {
        return window.location.assign(link);
    };

    return (
        <div>
            <button
                onClick={handleLogin}
                className=" bg-[#FEE500] text-[#381E1F] py-3 my-4 flex items-center w-full rounded-lg px-12"
            >
                <img
                    src={kakao}
                    alt="kakao"
                    className="w-4 h-4 mr-4 rounded-md"
                />
                <div className="text-sm font-medium">카카오 계정으로 시작하기</div>
            </button>
        </div>
    );
}
