import React, { useEffect, useState } from "react";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "components/Buttons/CustomButton";
import {
  useRequestDelete,
  useRequestGet,
  useRequestPost,
} from "hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import { FaCheck } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { PlanMatcherToKor } from "utils/PlanMatcher";
import MypageCard from "../components/MypageCard";
import DeleteModal from "components/Modals/DeleteModal";
import { toast } from "react-toastify";
import PlanList from "../components/PlanList";
import Pagination from "components/Pagination/Pagination";
const Payment = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tossPayments, setTossPayments] = useState(null);
  const [billingKey, setBillingKey] = useState(""); // 빌링 키 상태 추가
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const getUserInfo = useRequestGet("/api/user", "userInfo");
  const getPaymentInfo = useRequestGet(
    `/api/user/payments?&page=${page}&size=10`,
    "getPaymentInfo"
  );
  const postBillingKey = useRequestPost();
  const cardInfo = useRequestGet("/api/user/billing-key", "getCardInfo");
  const getCustomertKey = useRequestGet(
    "/api/user/customer-key",
    "customerKey"
  );
  const authKey = searchParams.get("authKey");
  const cardNumber = cardInfo?.data?.data?.cardNumber;
  const cardCompany = cardInfo?.data?.data?.cardCompany;
  const planName = getUserInfo?.data?.data?.planName;
  const customerKey = getCustomertKey.data?.data?.customerKey;
  const email = getUserInfo?.data?.data?.userId;
  const username = getUserInfo?.data?.data?.name;
  useEffect(() => {
    if (location.state) {
      localStorage.setItem("paymentInfo", JSON.stringify(location.state));
    }

    const clientKey = process.env.REACT_APP_CLIENT_KEY;

    if (!clientKey) {
      console.error("클라이언트 키가 정의되지 않았습니다.");
      return;
    }

    loadTossPayments(clientKey)
      .then((tossPaymentsInstance) => {
        setTossPayments(tossPaymentsInstance);
        if (authKey) {
          generateBillingKeyAuth();
        }
      })
      .catch((error) => {
        console.error("Tosspayments SDK 로드 실패", error);
        toast.error("결제 시스템을 초기화하는 중 오류가 발생했습니다.");
      });
  }, [authKey]);

  useEffect(() => {
    // 카드 정보가 로드된 후 빌링 키를 설정
    if (cardInfo.data && cardInfo.data.data && cardInfo.data.data.billingKey) {
      setBillingKey(cardInfo.data.data.billingKey); // 카드 정보에서 빌링 키 가져오기
    }
  }, [cardInfo]);

  const handleCardRegistration = async () => {
    if (!tossPayments) {
      toast.error("결제 시스템을 초기화 중입니다.");
      return;
    }

    try {
      await tossPayments.requestBillingAuth("CARD", {
        customerKey: customerKey,
        successUrl: window.location.origin + "/mypage?tab=payment",
        failUrl: window.location.origin + "/plan/payment/fail",
        customerEmail: email,
        customerName: username,
      });
    } catch (error) {
      console.error("카드 등록 실패", error);
      toast.error("카드 등록 중 오류가 발생했습니다.");
    }
  };

  const generateBillingKeyAuth = () => {
    const requestData = {
      authKey: authKey,
      customerKey: searchParams.get("customerKey"),
    };

    postBillingKey.mutate(
      {
        requestUrl: `/api/toss-payments/generate-billing-key-auth`,
        postData: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getCardInfo");
        },
      }
    );
  };

  // 빌링 키 상태 변경 감지
  useEffect(() => {
    if (postBillingKey.status === "success") {
      navigate("/mypage?tab=payment");
      toast.success("카드 등록 및 빌링 키 발급이 완료되었습니다.");
    } else if (postBillingKey.status === "error") {
      console.error("빌링 키 발급 요청 실패", postBillingKey.error);
      toast.error("결제 요청 중 문제가 발생했습니다.");
    }
  }, [
    postBillingKey.status,
    postBillingKey.data,
    postBillingKey.error,
    navigate,
  ]);

  const deleteFeedCommentMutation = useRequestDelete(["getCardInfo"]);

  const handleDelete = () => {
    deleteFeedCommentMutation.mutate(
      {
        url: `/api/user/billing-key/delete`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getCardInfo");
          setIsDeleteModalOpen(false); // 삭제 성공 시 모달 닫기
        },
      }
    );
  };
  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true); // 삭제 모달 열기
  };

  const handleDeleteConfirm = () => {
    handleDelete(); // 삭제 확인 시 실제 삭제 실행
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // 모달 닫기
  };

  return (
    <>
      <div className="mt-5 w-[780px] m-auto">
        <MypageCard
          subTitle={"나의 요금제"}
          title={`${PlanMatcherToKor(planName)} 요금제`}
          cardNum={cardNumber}
          cardCompany={cardCompany}
        />
        <div className="mt-[20px]">
          <h3 className="text-lg font-semibold ">정기 결제 카드 정보</h3>
          {cardNumber ? (
            <div className="">
              <div className="flex gap-2 items-center mt-4 ">
                <div className=" w-full text-text-gray py-1 px-4 rounded-lg  border-text-lGray border text-center">
                  {cardNumber}
                </div>
                <button onClick={() => handleDeleteClick()}>
                  <FiTrash2 className="w-6 h-6 text-text-lGray" />
                </button>
              </div>

              <p className="flex gap-1 items-center text-gray-dGray text-sm">
                <FaCheck className="text-main-red w-3 h-3 mr-1" />
                결제 카드는 한개만 등록할 수 있으며, 변경시 삭제한 다음
                추가해주세요
              </p>
            </div>
          ) : (
            <CustomButton
              content="결제 카드를 추가해주세요"
              customStyle={
                "text-text-gray py-1 px-4 rounded-lg border-text-lGray border w-full mt-2"
              }
              func={handleCardRegistration} // 결제 카드 등록 핸들러 추가
            />
          )}
        </div>
        <PlanList paymentInfo={getPaymentInfo?.data?.data?.data} />
        <div className="p-5">
          <Pagination
            totalPage={getPaymentInfo?.data?.data?.pageInfo.totalPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="카드를 삭제하시겠습니까?"
        />
      )}
    </>
  );
};
export default Payment;
