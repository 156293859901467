// hooks
import { useState, useEffect } from 'react';
import { useRequestGet, useRequestPost } from 'hooks/useRequest';
import { useNavigate, useSearchParams } from 'react-router-dom';
// commons
import TemplateDropdown from 'components/Dropdowns/TemplateDropdown';
import KeywordTagInput from 'components/Inputs/KeywordTagInput';
import RecommandKeywordModal from 'pages/Template/components/Modals/RecommandKeywordModal';
import TextArea from 'components/TextArea';
import AITypeDropdown from 'components/Dropdowns/AITypeDropdown';
// utils
import { objectiveCode, templateCode, toneCode, languageCode } from 'utils/TemplateQuestions';
// icons
import { FaPlusSquare } from 'react-icons/fa';
//images
import plum_byte_icon_logo from 'assets/logo/plum_byte_icon_logo.png';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
import loadingImg from 'assets/images/loadingImg.gif';

const Diary = ({ templateInfo }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');

    const getBrand = useRequestGet('/api/brand', 'brand'); //getBrand.data.data.data
    const getTarget = useRequestGet('/api/target', 'target'); //getBrand.data.data.data
    const AiTypeList = [
        { type: 'gpt', title: 'GPT', describe: '창의적인 텍스트 생성' },
        { type: 'claude', title: 'Claude', describe: '자연스러운 텍스트 생성' },
    ];
    const [aiType, setAiType] = useState(AiTypeList[0].type);
    const [inputDatas, setInputDatas] = useState([]);

    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [target, setTarget] = useState('');
    const [objective, setObjective] = useState('');
    const [tone, setTone] = useState('');
    const [language, setLanguage] = useState(''); // 프로필의 언어

    const [firstVariable, setFirstVariable] = useState([]); // 있었던 일
    const [secondVariable, setSecondVariable] = useState('');
    const [thirdVariable, setThirdVariable] = useState('');
    const [fourthVariable, setFourthVariable] = useState('');
    const [fifthVariable, setFifthVariable] = useState('');

    const handleAddFirstInputs = () => {
        // 첫번째 인풋이 배열이므로 배열 안의 값들을 관리하기 위함
        setFirstVariable([...firstVariable, '']); // 새로운 빈 입력 필드를 추가
    };

    const handleInputChange = (index, value) => {
        const newFirstVariable = [...firstVariable];
        newFirstVariable[index] = value;
        setFirstVariable(newFirstVariable);
    };
    // 필수 입력 progress
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const firstVariableStr = firstVariable.join(',');
        const values = [firstVariableStr && firstVariableStr.length > 0, tone, language];
        const trueCount = values.filter(Boolean).length;
        const result = trueCount * 34 > 100 ? 100 : trueCount * 34;
        setProgress(result);
    }, [firstVariable, tone, language]);

    // 요청보내기
    const postTemplateCreate = useRequestPost();
    const postContentCreate = useRequestPost();
    const handleSubmit = () => {
        const firstVariableStr = firstVariable.join(',');
        if (progress === 100) {
            const data = {
                model: aiType,
                templateCode: templateCode.diary[0].template_code,

                ...(brand && { brandSeq: brand }),
                ...(target && { targetSeq: target }),
                ...(tone && { toneCode: tone }),
                ...(language && { countryCode: language }),
                ...(objective && { objectiveCode: objective }),

                ...(firstVariableStr && { firstVariable: firstVariableStr }),
                ...(secondVariable && { secondVariable: secondVariable?.join(',') }),
                ...(thirdVariable && { thirdVariable: thirdVariable }),
                ...(fourthVariable && { fourthVariable: fourthVariable }),
                ...(fifthVariable && { fifthVariable: fifthVariable }),
            };
            setInputDatas(data);
            postTemplateCreate.mutate({
                // 템플릿 생성 요청
                requestUrl: '/api/chat-completion/run',
                postData: data,
            });
            postContentCreate.mutate({
                // 콘텐츠 항목 생성 요청
                requestUrl: `/api/project/${projectSeq}/content`,
                postData: data,
            });
        }
    };
    // 요청 완료 컨트롤러
    useEffect(() => {
        if (postTemplateCreate.status === 'success' && postContentCreate.status === 'success') {
            const { value } = postTemplateCreate?.data?.data;
            const { seq } = postContentCreate?.data?.data;
            // 이미지 생성하기도 필요

            navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
                state: {
                    post: value,
                    contentSeq: seq,
                    projectSeq: projectSeq,
                    inputDatas: inputDatas,
                    imageData: '',
                },
            });
        } else {
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postTemplateCreate.status, postContentCreate.status]);
    return (
        <div className="mt-10">
            {(postTemplateCreate.isPending || postContentCreate.isPending) && (
                <div className="w-full h-screen bg-[#00000040] z-40 fixed flex justify-center items-center left-0 top-0">
                    <div className="relative">
                        <img
                            src={loadingImg}
                            alt="loading"
                            className="w-48 h-48"
                        />
                    </div>
                </div>
            )}

            <div>
                <AITypeDropdown
                    selectValue={aiType}
                    setSelectValue={setAiType}
                    itemList={AiTypeList}
                />
            </div>
            <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div>
            {/* 기본 input*/}
            <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
                <div>콘텐츠의 정확성 일치율</div>
                <div className="ml-2 font-bold text-main-pink">{progress}%</div>
            </div>
            <div className="mt-2 rounded-full bg-gray-lGray">
                <div
                    className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
                    style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
                ></div>
            </div>
            {/* 변수 input*/}
            <div className="pt-8">
                <div>
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>오늘 있었던 일
                    </div>

                    {firstVariable.map((el, idx) => (
                        <input
                            key={idx}
                            type="text"
                            value={el}
                            onChange={(e) => handleInputChange(idx, e.target.value)}
                            className="w-full p-2 mt-2 rounded-xl focus:outline-none"
                            placeholder={`사건 ${idx + 1}`}
                        />
                    ))}
                    <div
                        className="flex items-center justify-center p-[6px] mt-2 border-2 border-dashed rounded-lg text-gray-dGray border-gray-gray cursor-pointer"
                        onClick={handleAddFirstInputs}
                    >
                        <FaPlusSquare />
                        <div className="ml-2">있었던 일 추가</div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                        <div className="flex justify-between">
                            <div className="font-semibold">
                                <span className="m-1 text-main-pink">*</span>
                                톤앤매너
                            </div>
                        </div>
                        <TemplateDropdown
                            selectValue={tone}
                            setSelectValue={setTone}
                            itemList={toneCode.all}
                            mainCode={'tone_code'}
                            viewValueCode={'tone_name_kor'}
                        />
                    </div>
                    <div>
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink">*</span>
                            언어
                        </div>
                        <TemplateDropdown
                            selectValue={language}
                            setSelectValue={setLanguage}
                            itemList={languageCode}
                            mainCode={'language_code'}
                            viewValueCode={'language_name_kor'}
                        />
                    </div>
                </div>
            </div>

            <div className="flex justify-center w-full mt-8 text-sm">
                <button
                    onClick={handleSubmit}
                    className={
                        progress === 100
                            ? 'm-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]'
                            : 'm-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray'
                    }
                >
                    {'(-3Byte) 생성하기'}
                </button>
            </div>
        </div>
    );
};
export default Diary;
