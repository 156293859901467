import naver from 'assets/logo/social/naver.png';
import { SiNaver } from 'react-icons/si';

export default function NaverBtn() {
    // const link = `http://43.200.27.171:8080/oauth2/authorization/naver`;
    const link = `https://dev.plumbyte-ai.com/oauth2/authorization/naver`;
    const handleLogin = () => {
        return window.location.assign(link);
    };
    return (
        <div>
            <button
                onClick={handleLogin}
                className="bg-[#03C75A] text-white py-3 my-4 flex items-center w-full rounded-lg px-12"
            >
                <SiNaver className="w-4 h-4 mr-2 text-white" />
                <div className="text-sm font-medium">네이버 계정으로 시작하기</div>
            </button>
        </div>
    );
}
