// hooks
import { useSearchParams } from "react-router-dom";
import { useRequestPost, useRequestGet } from "hooks/useRequest";
// common
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import { PiTextTBold } from "react-icons/pi";
import { LuImage } from "react-icons/lu";
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
// icons
import CreateImageModal from "pages/BehanceEditor/components/Modals/CreateImageModal";
import CreateTextModal from "pages/BehanceEditor/components/Modals/CreateTextModal";
// utils
import { templateCode, toneCode } from "utils/TemplateQuestions";
import { useEffect, useState } from "react";
// images
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
import loadingImg from "assets/images/loadingImg.gif";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setReCreateAIText } from "../../../../redux/aiTextSlice";
import { setReCreateAIImage } from "../../../../redux/aiImageSlice";
import { setIsRefetchPlum } from "../../../../redux/isRefetchPlum";

const AIModal = ({
  isShow,
  setIsShow,
  modalBtn,
  insertionIdx,
  model = "gpt",
  AIModalTab,
  setAIModalTab,
  handleCreateMedia,
  handleEditMedia,
  deafultAIModalInput = "",
  beforeParagraph,
  nextParagraph,
}) => {
  const dispatch = useDispatch();
  const reCreateAIText = useSelector((state) => state.text.reCreateAIText);
  const reCreateAIImage = useSelector((state) => state.image.reCreateAIImage);
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const contentSeq = searchParams.get("contentSeq");
  const imageAICode = templateCode.editor[0]; // template_plum : 2
  const imageWithTextAICode = templateCode.editor[1]; // template_plum : 1
  const rewriteAICode = templateCode.editor[2]; // template_plum : 1byte C05
  const rewriteAIParagraph = templateCode.editor[3]; // template_plum : 1byte C06
  // editor[0] = 이미지 프롬프트 생성, editor[1] = 게시글+이미지 프롬프트 생성, editor[1] = 글 다시쓰기
  const imageRatio = [
    { ratio: "21:9" },
    { ratio: "16:9" },
    { ratio: "5:4" },
    { ratio: "3:2" },
    { ratio: "1:1" },
    { ratio: "2:3" },
    { ratio: "4:5" },
    { ratio: "9:16" },
    { ratio: "9:12" },
  ];
  const [createdAIImage, setCreatedAIImage] = useState([]);
  const [ratio, setRatio] = useState(imageRatio[0].ratio);
  const [tone, setTone] = useState("");
  const [codeState, setCodeState] = useState(imageWithTextAICode);
  const [aiModalInput, setAiModalInput] = useState(deafultAIModalInput);
  const [createdAiTexts, setCreatedAiTexts] = useState([]);
  const [AIModalTabState, setAIModalTabState] = useState(AIModalTab);

  const handleClose = (e) => {
    e.stopPropagation();
    if (setIsShow) setIsShow(false);
  };
  const handleAIModalTab = (e, tab) => {
    e.stopPropagation();
    setAIModalTabState(tab);
  };

  // redux로 상태 업데이트
  const handleReCreateAITextSave = (inputText) => {
    dispatch(setReCreateAIText(inputText));
  };
  const handleReCreateAIImageSave = (inputText) => {
    dispatch(setReCreateAIImage(inputText));
  };

  // project/seq/content/seq/images
  const getCreatedAIImg = useRequestGet(
    `api/project/${projectSeq}/content/${contentSeq}/images`,
    `createdAIImages-${projectSeq}-${contentSeq}`
  );
  useEffect(() => {
    if (getCreatedAIImg.status === "success") {
      const tempGetImages = getCreatedAIImg?.data?.data?.data;
      setCreatedAIImage(tempGetImages);
    }
  }, [getCreatedAIImg.status]);

  useEffect(() => {
    if (AIModalTabState === "AIText") setCodeState(rewriteAIParagraph);
    else if (AIModalTabState === "AIImage") setCodeState(imageAICode);
  }, [AIModalTabState]);

  // create textPrompt
  const postTextAI = useRequestPost();
  const postImageAI = useRequestPost();
  const handlePostAI = (e, reCreateText = "") => {
    e.preventDefault();
    if (aiModalInput && AIModalTabState === "AIText") {
      if (tone) {
        const data = {
          model: model,
          templateCode: codeState.template_code,
          toneCode: tone, //
          ...(beforeParagraph && { secondVariable: beforeParagraph }),
          ...(nextParagraph && { thirdVariable: nextParagraph }),
          fourthVariable: aiModalInput, // 반드시 포함되어야 할 내용
        };
        postTextAI.mutate({
          requestUrl: "/api/chat-completion/run",
          postData: data,
        });
        // redux 함수 호출
        handleReCreateAITextSave(aiModalInput);
        dispatch(setIsRefetchPlum(true));
      } else {
        toast.error("톤을 선택하세요.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else if (
      aiModalInput &&
      (AIModalTabState === "AIImage" || AIModalTabState === "IMAGE")
    ) {
      const data = {
        templateCode: codeState.template_code,
        prompt: aiModalInput,
        imageRatio: ratio,
      };
      postImageAI.mutate({
        requestUrl: `/api/project/${projectSeq}/content/${contentSeq}/images/create/v2`,
        postData: data,
      });
      if (AIModalTabState) {
        // redux 함수 호출
        handleReCreateAIImageSave(aiModalInput);
        dispatch(setIsRefetchPlum(true));
      }
    }
  };

  useEffect(() => {
    if (postTextAI?.status === "success") {
      const { value } = postTextAI.data?.data;
      setCreatedAiTexts([...createdAiTexts, value]);
    }
  }, [postTextAI.status]);

  useEffect(() => {
    if (postImageAI?.status === "success") {
      const newImage = postImageAI.data?.data;
      setCreatedAIImage([...createdAIImage, newImage]);
    }
  }, [postImageAI.status]);
  return (
    <div>
      {(postTextAI.isPending || postImageAI.isPending) && (
        <div className="w-full h-screen bg-[#00000040] z-40 fixed flex justify-center items-center left-0 top-0">
          <div className="relative">
            <img src={loadingImg} alt="loading" className="w-48 h-48" />
          </div>
        </div>
      )}
      <div className="">{modalBtn}</div>

      {isShow && (
        <div className="select-none">
          <div
            className="fixed top-0 left-0 z-30 w-screen h-screen "
            onClick={handleClose}
          ></div>
          {/* 이미지셀렉트 박스 */}
          <div
            className="fixed left-0 z-30 w-full bottom-10"
            onClick={handleClose}
          >
            {AIModalTabState === "AIText" && createdAiTexts.length > 0 ? (
              <CreateTextModal
                setIsShow={setIsShow}
                createdAiTexts={createdAiTexts}
                insertionIdx={insertionIdx}
                handleCreateMedia={handleCreateMedia}
                handlePostAI={handlePostAI}
              />
            ) : AIModalTabState === "IMAGE" ? (
              <CreateImageModal
                setIsShow={setIsShow}
                insertionIdx={insertionIdx}
                handleCreateMedia={handleCreateMedia}
                handleEditMedia={handleEditMedia}
                createdAIImage={createdAIImage}
              />
            ) : AIModalTabState === "AIImage" && createdAIImage.length > 0 ? (
              <CreateImageModal
                setIsShow={setIsShow}
                insertionIdx={insertionIdx}
                handleCreateMedia={handleCreateMedia}
                handleEditMedia={handleEditMedia}
                createdAIImage={createdAIImage}
                handlePostAI={handlePostAI}
              />
            ) : (
              <></>
            )}
            <div
              onClick={(e) => e.stopPropagation()}
              className="p-1 mt-4 items-center text-base border shadow-md m-auto shadow-[#eb228330] rounded-lg w-fit flex bg-text-white border-main-red"
            >
              <PiTextTBold
                onClick={(e) => {
                  handleAIModalTab(e, "AIText");
                }}
                className={
                  AIModalTabState === "AIText"
                    ? "text-main-pink mx-1 w-5 h-5"
                    : "w-5 h-5 text-gray-gray mx-1"
                }
              />
              <LuImage
                onClick={(e) => {
                  handleAIModalTab(e, "AIImage");
                }}
                className={
                  AIModalTabState === "AIImage" || AIModalTabState === "IMAGE"
                    ? "text-main-pink mr-1 w-5 h-5"
                    : "w-5 h-5 text-gray-gray mr-1"
                }
              />
              <input
                placeholder="텍스트를 입력해주세요"
                className="w-56 mr-2 text-sm focus:outline-none"
                onClick={(e) => e.stopPropagation()}
                value={aiModalInput}
                onChange={(e) => setAiModalInput(e.target.value)}
              />
              <div className="">
                {AIModalTabState === "AIText" ? (
                  <TemplateDropdown
                    placeholderValue="톤 선택"
                    backgroundStyle={"#F8F9FA"}
                    selectValue={tone}
                    setSelectValue={setTone}
                    itemList={toneCode.all}
                    mainCode={"tone_code"}
                    viewValueCode={"tone_name_kor"}
                  />
                ) : (
                  <TemplateDropdown
                    backgroundStyle={"#F8F9FA"}
                    selectValue={ratio}
                    setSelectValue={setRatio}
                    itemList={imageRatio}
                    mainCode={"ratio"}
                    viewValueCode={"ratio"}
                  />
                )}
              </div>
              <button
                onClick={handlePostAI}
                className="w-24 py-3 ml-1 text-xs rounded-lg text-text-white bg-main-red"
              >
                {`(-${codeState.template_plum}Byte)생성`}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIModal;
