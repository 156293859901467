// hooks
import { useState, useEffect } from 'react';
import { useRequestGet, useRequestPost } from 'hooks/useRequest';
import { useNavigate, useSearchParams } from 'react-router-dom';
// commons
import TemplateDropdown from 'components/Dropdowns/TemplateDropdown';
import KeywordTagInput from 'components/Inputs/KeywordTagInput';
import TextArea from 'components/TextArea';
import AITypeDropdown from 'components/Dropdowns/AITypeDropdown';
import RecommandKeywordModal from 'pages/Template/components/Modals/RecommandKeywordModal';

// utils
import { objectiveCode, templateCode, toneCode, languageCode } from 'utils/TemplateQuestions';
// icons
import { FaSquarePlus, FaSquareMinus } from 'react-icons/fa6';
//images
import plum_byte_icon_logo from 'assets/logo/plum_byte_icon_logo.png';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
import loadingImg from 'assets/images/loadingImg.gif';

const Notice = ({ templateInfo }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');

    const getBrand = useRequestGet('/api/brand', 'brand'); //getBrand.data.data.data
    const getTarget = useRequestGet('/api/target', 'target'); //getBrand.data.data.data
    const AiTypeList = [
        { type: 'gpt', title: 'GPT', describe: '창의적인 텍스트 생성' },
        { type: 'claude', title: 'Claude', describe: '자연스러운 텍스트 생성' },
    ];
    const [aiType, setAiType] = useState(AiTypeList[0].type);
    const [inputDatas, setInputDatas] = useState([]);

    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [target, setTarget] = useState('');
    const [objective, setObjective] = useState('');
    const [tone, setTone] = useState('');
    const [language, setLanguage] = useState('KR'); //언어 선택은 필수로 넣어야 하더라

    const [firstVariable, setFirstVariable] = useState('');
    const [secondVariable, setSecondVariable] = useState(''); // 공지 유형
    const [thirdVariable, setThirdVariable] = useState(''); // 주요 내용
    const [fourthVariable, setFourthVariable] = useState(''); // 적용 날짜 및 시간
    const [fifthVariable, setFifthVariable] = useState(''); // 사용자에게 미치는 영향

    const [imageRequestDescription, setImageRequestDescription] = useState('');
    // 추가설정
    const [addSetting, setAddSeting] = useState(false);
    // 필수 입력 progress
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const values = [secondVariable, thirdVariable, fourthVariable, fifthVariable];
        const trueCount = values.filter(Boolean).length;
        const result = trueCount * 25;
        setProgress(result);
    }, [secondVariable, thirdVariable, fourthVariable, fifthVariable]);

    // 요청보내기
    const postTemplateCreate = useRequestPost();
    const postContentCreate = useRequestPost();
    const postImageCreate = useRequestPost();

    const handleSubmit = () => {
        if (progress === 100) {
            const data = {
                model: aiType,
                templateCode: templateCode.notice[0].template_code,

                ...(brand && { brandSeq: brand }),
                ...(target && { targetSeq: target }),
                ...(tone && { toneCode: tone }),
                ...(language && { countryCode: language }),
                ...(objective && { objectiveCode: objective }),

                ...(firstVariable && { firstVariable: firstVariable }),
                ...(secondVariable && { secondVariable: secondVariable }),
                ...(thirdVariable && { thirdVariable: thirdVariable }),
                ...(fourthVariable && { fourthVariable: fourthVariable }),
                ...(fifthVariable && { fifthVariable: fifthVariable }),
            };
            setInputDatas(data);
            postTemplateCreate.mutate({
                // 템플릿 생성 요청
                requestUrl: '/api/chat-completion/run',
                postData: data,
            });
            postContentCreate.mutate({
                // 콘텐츠 항목 생성 요청
                requestUrl: `/api/project/${projectSeq}/content`,
                postData: data,
            });
        }
    };

    // 이미지 요청
    useEffect(() => {
        if (postContentCreate.status === 'success') {
            if (imageRequestDescription) {
                const { seq } = postContentCreate?.data?.data;
                const data = {
                    templateCode: 'C04',
                    prompt: imageRequestDescription,
                    imageRatio: '16:9',
                };

                postImageCreate.mutate({
                    requestUrl: `/api/project/${projectSeq}/content/${seq}/images/create/v2`,
                    postData: data,
                });
            } else return;
        }
    }, [postContentCreate.status]);

    // 요청 완료 컨트롤러
    useEffect(() => {
        if (imageRequestDescription) {
            if (
                postTemplateCreate.status === 'success' &&
                postContentCreate.status === 'success' &&
                postImageCreate.status === 'success'
            ) {
                const { value } = postTemplateCreate?.data?.data;
                const { seq } = postContentCreate?.data?.data;
                // 이미지 생성하기도 필요
                const { imageS3Url } = postImageCreate?.data?.data;

                navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
                    state: {
                        post: value,
                        contentSeq: seq,
                        projectSeq: projectSeq,
                        inputDatas: inputDatas,
                        imageData: imageS3Url,
                    },
                });
            } else {
                return;
            }
        } else if (!imageRequestDescription) {
            if (postTemplateCreate.status === 'success' && postContentCreate.status === 'success') {
                const { value } = postTemplateCreate?.data?.data;
                const { seq } = postContentCreate?.data?.data;
                // 이미지 생성하기도 필요
                //   const { imageS3Url } = postImageCreate?.data;
                navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
                    state: {
                        post: value,
                        contentSeq: seq,
                        projectSeq: projectSeq,
                        inputDatas: inputDatas,
                        imageData: '',
                    },
                });
            } else {
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postTemplateCreate.status, postContentCreate.status, postImageCreate.status]);
    return (
        <div className="mt-10">
            {(postTemplateCreate.isPending || postContentCreate.isPending || postImageCreate.isPending) && (
                <div className="w-full h-screen bg-[#00000040] z-40 fixed flex justify-center items-center left-0 top-0">
                    <div className="relative">
                        <img
                            src={loadingImg}
                            alt="loading"
                            className="w-48 h-48"
                        />
                    </div>
                </div>
            )}

            <div>
                <AITypeDropdown
                    selectValue={aiType}
                    setSelectValue={setAiType}
                    itemList={AiTypeList}
                />
            </div>
            <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div>
            {/* 기본 input*/}
            <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
                <div>콘텐츠의 정확성 일치율</div>
                <div className="ml-2 font-bold text-main-pink">{progress}%</div>
            </div>
            <div className="mt-2 rounded-full bg-gray-lGray">
                <div
                    className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
                    style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
                ></div>
            </div>
            {/* 변수 input*/}
            <div className="pt-8">
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink">*</span>
                            공지 유형
                        </div>
                        <input
                            type="text"
                            className="w-full p-2 rounded-xl focus:outline-none"
                            onChange={(e) => setSecondVariable(e.target.value)}
                            placeholder="업데이트"
                        />
                    </div>

                    <div>
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink">*</span>
                            적용 날짜 및 시간
                        </div>
                        <input
                            type="text"
                            className="w-full p-2 rounded-xl focus:outline-none"
                            onChange={(e) => setFourthVariable(e.target.value)}
                            placeholder="2024년 7월 5일 09:00"
                        />
                    </div>

                    <div className="col-span-2">
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink"> *</span>
                            주요 내용
                        </div>
                        <TextArea
                            id="text"
                            type="white"
                            placeholder="내용 입력"
                            value={thirdVariable}
                            setValue={setThirdVariable}
                            rows={6}
                        />
                    </div>

                    <div className="col-span-2">
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink">*</span>
                            사용자에게 미치는 영향
                        </div>
                        <input
                            type="text"
                            className="w-full p-2 rounded-xl focus:outline-none"
                            onChange={(e) => setFifthVariable(e.target.value)}
                            placeholder="내용입력"
                        />
                    </div>
                </div>
            </div>
            {/* 추가 설정 */}
            <div
                className={
                    addSetting
                        ? 'w-full border-2 border-main-pink rounded-lg my-8 bg-text-white'
                        : 'w-full border-2 border-gray-gray border-dashed rounded-lg my-8'
                }
            >
                <div
                    className={
                        addSetting
                            ? 'flex justify-center items-center text-sm text-main-pink py-2 bg-[#eb228310] rounded-lg font-semibold'
                            : 'flex justify-center items-center text-sm text-gray-gray py-2 rounded-lg font-semibold'
                    }
                >
                    {addSetting ? (
                        <FaSquareMinus className="w-5 h-5 mr-2" />
                    ) : (
                        <FaSquarePlus className="w-5 h-5 mr-2" />
                    )}
                    <button
                        type="button"
                        onClick={() => setAddSeting(!addSetting)}
                    >
                        추가 설정
                    </button>
                </div>

                {addSetting && (
                    <div className="grid grid-cols-2 gap-4 p-4">
                        <div>
                            <div className="font-semibold">담당자</div>
                            <input
                                type="text"
                                className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                                onChange={(e) => setFirstVariable(e.target.value)}
                                placeholder="내용 입력"
                            />
                        </div>

                        <div>
                            <div className="font-semibold">언어</div>
                            <TemplateDropdown
                                backgroundStyle={'#F8F9FA'}
                                selectValue={language}
                                setSelectValue={setLanguage}
                                itemList={languageCode}
                                mainCode={'language_code'}
                                viewValueCode={'language_name_kor'}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-center w-full mt-8 text-sm">
                <button
                    onClick={handleSubmit}
                    className={
                        progress === 100
                            ? 'm-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]'
                            : 'm-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray'
                    }
                >
                    (-2Byte) 생성하기
                </button>
            </div>
        </div>
    );
};
export default Notice;
