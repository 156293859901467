import { IoClose } from "react-icons/io5";
import band_banner_logo from "assets/logo/band_banner_logo.png";

const BandBanner = ({ onClose }) => {
  return (
    <div className="fixed top-0 z-20 w-screen bg-gradient-to-bl from-main-pink via-[#C41D99] to-main-purple ">
      <div
        className="cherry-header-wrapper flex items-center justify-between"
        style={{ height: "65px" }}
      >
        <img alt="band_banner_logo" src={band_banner_logo} className="w-10" />
        <div className="flex gap-2 text-sm text-text-white items-center">
          <p className="text-xs bg-white bg-opacity-50 rounded-md p-1">
            오픈 기념 이벤트
          </p>
          <p>한달 동안 무료로 플럼바이트를 이용해보실 수 있습니다</p>
        </div>
        <IoClose
          className="cursor-pointer text-text-white w-6 h-6"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default BandBanner;
