import google from 'assets/logo/social/google.png';

export default function GoogleBtn() {
    // const link = `http://43.200.27.171:8080/oauth2/authorization/google`;
    const link = `https://dev.plumbyte-ai.com/oauth2/authorization/google`;
    const handleLogin = () => {
        return window.location.assign(link);
    };
    return (
        <div>
            <button
                onClick={handleLogin}
                className="flex items-center w-full px-12 py-3 my-4 border rounded-lg border-gray-lGray"
            >
                <img
                    src={google}
                    alt="google"
                    className="w-4 h-4 mr-4 rounded-md"
                />
                <div className="text-sm font-medium">구글 계정으로 시작하기</div>
            </button>
        </div>
    );
}
