// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestPost } from 'hooks/useRequest';
// Landing Components
import LandingHeader from 'pages/Landing/components/LandingHeader';
import LandingFooter from 'pages/Landing/components/LandingFooter';
import LandingDropdown from 'components/Dropdowns/LandingDropdown';
// import Buttons
import CustomButton from 'components/Buttons/CustomButton';
// images
import loadingImg from 'assets/images/loadingImg.gif';
import plum_byte_font from 'assets/logo/plum_byte_font.png';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
import landing_main from 'assets/landing/landing_main.webp';
// common
import { toast } from 'react-toastify'; // Toastify import
import 'react-toastify/dist/ReactToastify.css'; // Toastify 스타일 import
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsMobile } from '../../redux/isMobileSlice';
const Landing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobile = useSelector((state) => state.mobile.isMobile);
    const [inputTitle, setInputTitle] = useState('');
    const [selectCategory, setSelectCategory] = useState('');
    const [finalPost, setFinamPost] = useState(null);
    const [finalImage, setFinalImage] = useState(null);

    // 모바일 사이즈설정
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 1024;
            dispatch(setIsMobile(isMobile));
        };
        // 초기 상태 설정
        handleResize();
        // 윈도우 리사이즈 이벤트에 핸들러를 연결
        window.addEventListener('resize', handleResize);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const creatPost = useRequestPost();
    const createLandingPost = (landingTitle, landingCategory) => {
        if (landingTitle && landingCategory) {
            creatPost.mutate({
                requestUrl: '/api/landing-content/post',
                postData: {
                    objective: landingTitle,
                    blogCategory: landingCategory,
                },
            });
        } else if (!landingCategory) {
            toast.error('카테고리를 선택해주세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (!landingTitle) {
            toast.error('주제를 입력해주세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.error('필수 입력값을 입력하시고 재시도해주세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    const createImage = useRequestPost();
    const createLandingImage = (postSubject) => {
        if (inputTitle && selectCategory) {
            createImage.mutate({
                requestUrl: `/api/landing-content/image?objective=${postSubject}`,
            });
        }
    };
    // POST createLanding Handler
    useEffect(() => {
        if (creatPost.status === 'success') {
            setFinamPost(creatPost.data.data);
        } else if (creatPost.status === 'pending') {
            // console.log("포스트 로딩중입니다..");
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creatPost.status]);
    useEffect(() => {
        if (createImage.status === 'success') {
            setFinalImage(createImage.data.data);
        } else if (createImage.status === 'pending') {
            // console.log("이미지 로딩중입니다..");
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createImage.status]);
    // Navigate
    useEffect(() => {
        if (finalImage && finalPost) {
            navigate('/created-landing', {
                state: {
                    category: selectCategory,
                    post: finalPost,
                    image: finalImage,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalPost, finalImage]);
    return (
        <div className={`flex items-center justify-center w-screen h-screen`}>
            <img
                alt="landing_main"
                src={landing_main}
                loading="lazy"
                className={
                    mobile
                        ? 'fixed top-0 left-0 w-full h-full object-cover'
                        : 'fixed top-0 left-0 w-full h-full object-cover'
                }
            />

            <LandingHeader />
            {(creatPost.isPending || createImage.isPending) && (
                <div className="w-full h-full bg-[#00000040] z-20 absolute flex justify-center items-center">
                    <div className="relative">
                        <img
                            src={loadingImg}
                            alt="loading"
                            className="w-48 h-48"
                        />
                    </div>
                </div>
            )}
            <div className="z-10 w-full text-center mt-[100px] ">
                <img
                    src={plum_byte_font}
                    alt="plum_byte_font"
                    className="m-auto w-[230px]"
                />

                <div
                    className={
                        mobile
                            ? 'flex items-center max-w-[460px] w-full h-[60px] p-1 m-auto mt-5 border bg-text-white rounded-2xl border-main-red'
                            : 'flex items-center w-[600px] h-[60px] p-1 m-auto mt-5 border bg-text-white rounded-2xl border-main-red'
                    }
                >
                    <LandingDropdown
                        selectValue={selectCategory}
                        setSelectValue={setSelectCategory}
                        itemList={[
                            'IT·컴퓨터',
                            '건강·의학',
                            '게임',
                            '공연·전시',
                            '교육·학문',
                            '국내여행',
                            '만화·애니',
                            '드라마',
                            '맛집',
                            '문학·책',
                            '미술·디자인',
                            '반려동물',
                            '방송',
                            '비즈니스·경제',
                            '사진',
                            '사회·정치',
                            '상품리뷰',
                            '해외여행',
                            '스타·연예인',
                            '스포츠',
                            '어학·외국어',
                            '영화',
                            '요리·레시피',
                            '원예·재배',
                            '육아·결혼',
                            '음악',
                            '인테리어·DIY',
                            '일상·생각',
                            '자동차',
                            '좋은글·이미지',
                            '취미',
                            '패션·미용',
                        ]}
                    />
                    <input
                        onChange={(e) => setInputTitle(e.target.value)}
                        placeholder="해방촌 텍사스 바베큐 맛집 'OOO'홍보"
                        className="w-full ml-2 rounded-lg focus:outline-none inputTextMinSize"
                    />
                    <div className="text-center">
                        <CustomButton
                            func={() => {
                                createLandingPost(inputTitle, selectCategory);
                                createLandingImage(`${inputTitle}`);
                            }}
                            content={'생성하기'}
                            customStyle={
                                mobile
                                    ? `text-text-white bg-back-black rounded-xl px-2 py-1 w-[76px] h-[48px] bg-gradient-to-r from-[#9218D4] to-[#D22585]`
                                    : `text-text-white bg-back-black rounded-xl w-[86px] h-[48px] bg-gradient-to-r from-[#9218D4] to-[#D22585]`
                            }
                        />
                    </div>
                </div>
            </div>

            <LandingFooter />
        </div>
    );
};

export default Landing;
