// hooks
import { useState, useEffect } from 'react';
import { useRequestGet, useRequestPost } from 'hooks/useRequest';
import { useNavigate, useSearchParams } from 'react-router-dom';
// commons
import TemplateDropdown from 'components/Dropdowns/TemplateDropdown';
import KeywordTagInput from 'components/Inputs/KeywordTagInput';
import RecommandKeywordModal from 'pages/Template/components/Modals/RecommandKeywordModal';
import TextArea from 'components/TextArea';
import AITypeDropdown from 'components/Dropdowns/AITypeDropdown';
// utils
import { objectiveCode, templateCode, toneCode, languageCode } from 'utils/TemplateQuestions';
// icons
import { FaSquarePlus, FaSquareMinus } from 'react-icons/fa6';
//images
import plum_byte_icon_logo from 'assets/logo/plum_byte_icon_logo.png';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
import loadingImg from 'assets/images/loadingImg.gif';

const LinkedinProfile = ({ templateInfo }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');

    const getBrand = useRequestGet('/api/brand', 'brand'); //getBrand.data.data.data
    const getTarget = useRequestGet('/api/target', 'target'); //getBrand.data.data.data
    const AiTypeList = [
        { type: 'gpt', title: 'GPT', describe: '창의적인 텍스트 생성' },
        { type: 'claude', title: 'Claude', describe: '자연스러운 텍스트 생성' },
    ];
    const [aiType, setAiType] = useState(AiTypeList[0].type);
    const [inputDatas, setInputDatas] = useState([]);

    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [target, setTarget] = useState('');
    const [objective, setObjective] = useState('');
    const [tone, setTone] = useState('');
    const [language, setLanguage] = useState(''); // 프로필의 언어

    const [firstVariable, setFirstVariable] = useState(''); // 소개
    const [secondVariable, setSecondVariable] = useState([]); // 키워드
    const [thirdVariable, setThirdVariable] = useState('');
    const [fourthVariable, setFourthVariable] = useState('');
    const [fifthVariable, setFifthVariable] = useState('');

    // 추가설정
    const [addSetting, setAddSeting] = useState(false);
    // 필수 입력 progress
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const values = [firstVariable, secondVariable?.length > 0, language];
        const trueCount = values.filter(Boolean).length;
        const result = trueCount * 34 > 100 ? 100 : trueCount * 34;
        setProgress(result);
    }, [firstVariable, secondVariable, language]);

    // 요청보내기
    const postTemplateCreate = useRequestPost();
    const postContentCreate = useRequestPost();
    const handleSubmit = () => {
        if (progress === 100) {
            const data = {
                model: aiType,
                templateCode: templateCode.linkedinProfile[0].template_code,

                ...(brand && { brandSeq: brand }),
                ...(target && { targetSeq: target }),
                ...(tone && { toneCode: tone }),
                ...(language && { countryCode: language }),
                ...(objective && { objectiveCode: objective }),

                ...(firstVariable && { firstVariable: firstVariable }),
                ...(secondVariable && { secondVariable: secondVariable?.join(',') }),
                ...(thirdVariable && { thirdVariable: thirdVariable }),
                ...(fourthVariable && { fourthVariable: fourthVariable }),
                ...(fifthVariable && { fifthVariable: fifthVariable }),
            };
            setInputDatas(data);
            postTemplateCreate.mutate({
                // 템플릿 생성 요청
                requestUrl: '/api/chat-completion/run',
                postData: data,
            });
            postContentCreate.mutate({
                // 콘텐츠 항목 생성 요청
                requestUrl: `/api/project/${projectSeq}/content`,
                postData: data,
            });
        }
    };

    // 요청 완료 컨트롤러
    useEffect(() => {
        if (postTemplateCreate.status === 'success' && postContentCreate.status === 'success') {
            const { value } = postTemplateCreate?.data?.data;
            const { seq } = postContentCreate?.data?.data;
            // 이미지 생성하기도 필요

            navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
                state: {
                    post: value,
                    contentSeq: seq,
                    projectSeq: projectSeq,
                    inputDatas: inputDatas,
                    imageData: '',
                },
            });
        } else {
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postTemplateCreate.status, postContentCreate.status]);
    return (
        <div className="mt-10">
            {(postTemplateCreate.isPending || postContentCreate.isPending) && (
                <div className="w-full h-screen bg-[#00000040] z-40 fixed flex justify-center items-center left-0 top-0">
                    <div className="relative">
                        <img
                            src={loadingImg}
                            alt="loading"
                            className="w-48 h-48"
                        />
                    </div>
                </div>
            )}

            <div>
                <AITypeDropdown
                    selectValue={aiType}
                    setSelectValue={setAiType}
                    itemList={AiTypeList}
                />
            </div>
            <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div>
            {/* 기본 input*/}
            <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
                <div>콘텐츠의 정확성 일치율</div>
                <div className="ml-2 font-bold text-main-pink">{progress}%</div>
            </div>
            <div className="mt-2 rounded-full bg-gray-lGray">
                <div
                    className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
                    style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
                ></div>
            </div>
            {/* 변수 input*/}
            <div className="pt-8">
                <div>
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>내 소개
                    </div>

                    <TextArea
                        id="text"
                        value={firstVariable}
                        setValue={setFirstVariable}
                        placeholder="생성 AI 콘텐츠 제작 서비스 '플럼바이트' 개발자"
                        rows={5}
                        type="white"
                    />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                        <div className="flex justify-between">
                            <div className="font-semibold">
                                <span className="m-1 text-main-pink">*</span>
                                핵심 키워드
                            </div>
                            <RecommandKeywordModal
                                variable={secondVariable} //키워드
                                setVariable={setSecondVariable} //키워드
                                modalBtn={<button className="text-xs text-main-pink">키워드 추천</button>}
                            />
                        </div>
                        <KeywordTagInput
                            tags={secondVariable}
                            setTags={setSecondVariable}
                            bgStyle="white"
                        />
                    </div>
                    <div>
                        <div className="font-semibold">
                            <span className="m-1 text-main-pink">*</span>
                            언어
                        </div>
                        <TemplateDropdown
                            selectValue={language}
                            setSelectValue={setLanguage}
                            itemList={languageCode}
                            mainCode={'language_code'}
                            viewValueCode={'language_name_kor'}
                        />
                    </div>
                </div>
            </div>

            <div className="flex justify-center w-full mt-8 text-sm">
                <button
                    onClick={handleSubmit}
                    className={
                        progress === 100
                            ? 'm-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]'
                            : 'm-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray'
                    }
                >
                    {'(-2Byte) 생성하기'}
                </button>
            </div>
        </div>
    );
};
export default LinkedinProfile;
