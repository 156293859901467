// hooks
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Login Components
import LoginEmail from 'pages/Login/components/LoginEmail';
import Signup from 'pages/Login/components/Signup';
// images
import login_main from 'assets/login/login_main.webp';
import login_typo from 'assets/login/login_typo.png';
// icons
import { FaArrowLeft } from 'react-icons/fa';
import { useState } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsMobile } from '../../../redux/isMobileSlice';
const Auth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobile = useSelector((state) => state.mobile.isMobile);
    // 모바일 사이즈설정
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 1000;
            dispatch(setIsMobile(isMobile));
        };
        // 초기 상태 설정
        handleResize();
        // 윈도우 리사이즈 이벤트에 핸들러를 연결
        window.addEventListener('resize', handleResize);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);
    const [tab, setTab] = useState('로그인');
    return (
        <div
            className={
                mobile ? 'flex justify-center items-center w-screen h-screen' : 'grid w-screen h-screen grid-cols-2 '
            }
        >
            {/* <LandingHeader /> */}

            {!mobile && (
                <div className="">
                    <img
                        onClick={() => navigate('/landing')}
                        alt="login_main"
                        src={login_main}
                        loading="lazy"
                        className="absolute inset-0 object-cover w-[50%] h-full"
                    />
                </div>
            )}
            <div className="flex items-center justify-center">
                <div className="max-w-screen-md w-80">
                    <img
                        src={login_typo}
                        alt="login_typo"
                        className="w-24 m-auto"
                    />
                    <button
                        onClick={() => navigate('/login')}
                        className="flex items-center mt-8 text-start text-gray-gray"
                    >
                        <FaArrowLeft className="mr-1" />
                        <div type="button">Back</div>
                    </button>

                    <div className="grid w-full grid-cols-2 gap-1 p-1 mt-2 font-bold rounded-md bg-gray-sGray">
                        <button
                            type="button"
                            className={
                                tab === '로그인'
                                    ? 'bg-text-white rounded-md py-1 text-main-pink'
                                    : ' rounded-md py-1 text-gray-dGray'
                            }
                            onClick={() => setTab('로그인')}
                        >
                            로그인
                        </button>
                        <button
                            type="button"
                            className={
                                tab === '회원가입'
                                    ? 'bg-text-white rounded-md py-1 text-main-pink'
                                    : ' rounded-md py-1 text-gray-dGray'
                            }
                            onClick={() => setTab('회원가입')}
                        >
                            회원가입
                        </button>
                    </div>
                    {/* tab Contents */}
                    <div>{tab === '로그인' ? <LoginEmail /> : <Signup setTab={setTab} />}</div>
                </div>
            </div>
        </div>
    );
};
export default Auth;
